<template >
  
    <div> 
    <DxPopup   id="popup" v-model:visible="popup" :show-title="true" :drag-enabled="false"
     :hide-on-outside-click="true"  :show-close-button="true" :title="$t('majetek-DetailMajetku')+ majetek.Name" width="85%" height="85%" >
        <DxPosition at="center" my="center"/>              
                <DxScrollView height="100%" width="100%" direction="vertical" showScrollbar="always">
                  <DxStateStoring
                  :enabled="true"
                   type="localStorage"
                  storage-key="storage"
                  />
                  <div id="popupMajetekObsah">
                    <table style="margin-left:15px; width: 100%">
                      <tr>
                        <td><h3 id="NaZamestnanceHeader" class="popupHeaderMajetek prevent-select">{{ $t('majetek-EvidovanoNaZamestnance') }}  </h3>   
                          <div  class="popupMajetekItemInfo">
                            <label id="NaZamestnanceObsah"  class="popupObsahMajetek" >{{ majetek.Zam }}</label>  
                          </div></td>
                          <td><h3 id="NachaziSeHeader" class="popupHeaderMajetek prevent-select">{{ $t('majetek-NachaziSe') }} </h3>
                            <div class="popupMajetekItemInfo">
                              <span id="NachaziSeObsah"  class="popupObsahMajetek">{{ majetek.Place }}</span> 
                            </div> </td>
                          <td><h3 id="PorizovaciCenaHeader" class="popupHeaderMajetek prevent-select">{{ $t('majetek-PorizovaciCena' ) + ':' }}</h3>
                            <div class="popupMajetekItemInfo">   
                             <span id="PorizovaciCenaObsah"  class="popupObsahMajetek">{{ majetek.PorCena }} CZK</span> 
                            </div></td>    
                      </tr>
                      <tr>
                        <td><h3 id="InventarniCisloHeader" class="popupHeaderMajetek prevent-select">{{ $t('majetek-InventarniCislo') }} </h3>
                            <div class="popupMajetekItemInfo">
                              <span id="InventarniCisloObsah"  class="popupObsahMajetek" >{{ majetek.InvC }}</span>  
                            </div></td>
                            <td><h3 id="VyrobniCisloHeader" class="popupHeaderMajetek prevent-select">{{ $t('majetek-VyrobniCislo') }} </h3>
                            <div class="popupMajetekItemInfo">
                              <span id="VyrobniCisloObsah"  class="popupObsahMajetek" >{{ majetek.VyrC }}</span>   
                            </div></td>
                        <td><h3 id="UmisteniHeader" class="popupHeaderMajetek prevent-select">{{ $t('majetek-DokladZarazeni') + ':' }} </h3>
                          <div class="popupMajetekItemInfo">
                            <span id="UmisteniObsah"  class="popupObsahMajetek" >{{ majetek.EvidenceDocument }}</span>
                          </div></td>     
                      </tr>
                      <tr>
                        <td><h3 id="StrediskoHeader" class="popupHeaderMajetek prevent-select">{{ $t('majetek-Grant') + ':' }} </h3>
                            <div class="popupMajetekItemInfo">
                              <span id="StrediskoObsah"  class="popupObsahMajetek" >{{ majetek.Grant }}</span>
                            </div></td>
                            <td><h3 id="SkupinaHeader" class="popupHeaderMajetek prevent-select">{{ $t('majetek-Skupina') }}  </h3>
                            <div class="popupMajetekItemInfo">
                              <span id="SkupinaObsah"  class="popupObsahMajetek" >{{ majetek.Group }}</span>
                            </div> </td>
                          <td><h3 id="OborHeader" class="popupHeaderMajetek prevent-select">{{ $t('majetek-Obor') }} </h3>
                          <div class="popupMajetekItemInfo">
                            <span id="OborObsah"  class="popupObsahMajetek" >{{ majetek.Department }}</span>  
                          </div></td>                                              
                        </tr>
                        <tr>
                            <td><h3 id="DateTimeHeader" class="popupHeaderMajetek">{{$t('majetek-DatumPorizeni' ) + ':' }} </h3>
                        <div class="popupMajetekItemInfo" >
                          <span id="PuchaseTimeDateBox"  class="popupObsahMajetek" >{{ majetek.PurchaseDate  }}</span>
                        </div></td>
                            <td><h3 id="DateTimeHeader" class="popupHeaderMajetek">{{ $t('majetek-Zarazeni') + ':' }} </h3>
                            <div class="popupMajetekItemInfo">
                              <span id="PuchaseTimeDateBox"  class="popupObsahMajetek" >{{ majetek.Placed }}</span>
                            </div></td>
                          <td><h3 id="PoznamkaHeader" class="popupHeaderMajetek prevent-select">{{ $t('majetek-Poznamka')  }} </h3>
                            <div class="popupMajetekItemInfo">
                              <span id="PoznamkaObsah"  class="popupObsahMajetek" >{{ majetek.Note }}</span> 
                            </div></td>
                          </tr>
                    </table>
                    <h2 id="MajetekHeader" class="nadpisH2Moduly">{{ $t('majetek-MajetekPohyby') }}</h2> 
                    <hr class="hrModuly">
                    <div class="bordersDataGridPozadavkyModuly" v-if="popup" >
                    <DxDataGrid column-resizing-mode="widget" :allow-column-resizing="true"   :column-auto-width="true" :hoverStateEnabled="true" :rowAlternationEnabled="true"
                            ref="mygridpoh" id="DataGridPohyby" :data-source="GridRowsMovement" :allow-column-reordering="true" :show-borders="true" :height="(vyskaDataGridPopup/3)" width="100%" 
                             :noDataText="$t('central-NenalezenyZadneZaznamy')"   :editing-texts="{ saveRowChanges: '*SAVE*', cancelRowChanges: '*CANCEL*', }" 
                          @exporting="onExporting" >
                          <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
                          <DxScrolling mode="standard" />
                          <DxGrouping :auto-expand-all="true"/>
                            <DxSelection mode="multiple"/>
                            <DxExport
                              :enabled="true"
                              :formats="['xlsx']"
                              :allow-export-selected-data="false"
                              :texts="{ exportAll: $t('central-ExportVseExcel') }"
                              />
                              <DxToolbar >
                                <DxItem location="before" locate-in-menu="auto"
                                        name="groupPanel"
                                        />
                                  <DxItem location="after" locate-in-menu="auto" widget="dxButton">
                                      <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                      type="success" style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined" @click="GridLayoutReset('mygridpoh')" />      
                                  </DxItem>
                                  <DxItem location="after" locate-in-menu="auto"
                                        name="exportButton"
                                        />
                                        <DxItem location="after" locate-in-menu="auto"
                                        name="searchPanel"
                                        :visible="true"
                                        />
                                
                                </DxToolbar>    
                            <DxStateStoring
                              :enabled="true"
                              type="localStorage"
                              storage-key="GridMajetekPohyby"
                            />

                                <DxColumn  data-field="movement" :caption="$t('majetek-Pohyb')" :visible="true">
                                </DxColumn>  
                                <DxColumn  data-field="date" :caption="$t('majetek-PohybDatum')" :visible="true">
                                  </DxColumn>
                                  <DxColumn  data-field="document" :caption="$t('majetek-PohybDoklad')" :visible="true">
                                  </DxColumn>
                                  <DxColumn  data-field="price" format=",##0.00" :caption="$t('majetek-PohybCena')" :visible="true">
                                  </DxColumn>
                                  <DxColumn  data-field="description" :caption="$t('majetek-PohybPopis')" :visible="true">
                                  </DxColumn>
                                  <DxColumn  data-field="empfrom" :caption="$t('majetek-PohybPuvodniZamestnanec')" :visible="true">
                                  </DxColumn>
                                  <DxColumn  data-field="empto" :caption="$t('majetek-PohybNaZamestnance')" :visible="true">
                                    </DxColumn>
                            <DxEditing :allow-updating="false" :allow-deleting="false" :allow-adding="true" mode="form" editRow="Upravit"
                              :texts="{
                                saveRowChanges: 'Povrdit', cancelRowChanges: 'Zrušit', editRow: '', deleteRow: 'Smazat',
                                confirmDeleteTitle: 'Varování', confirmDeleteMessage: 'Opravdu si přejete smazat tento záznam?',
                              }" />
                            <DxPaging :enabled="false" />
                            <DxGroupPanel :visible="true" :empty-panel-text="$t('majetek-PretahneteSemZahlavi')" />
                            <DxGrouping :auto-expand-all="true" />
                            <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
                            <DxSearchPanel :width="300" :visible="true" :placeholder="$t('majetek-Hledat')" />
                            <DxFilterRow :visible="true"/>
                                        <DxFilterPanel :visible="true" :texts="{
                                          clearFilter: $t('central-ZrusitFiltr'), createFilter: $t('central-VytvoritFiltr'), filterEnabledHint: $t('central-FiltrVysvetlivka'), saveChanges: 'Povrdit',
                                        }"/>
                                        <DxFilterBuilderPopup  value="text" />
                                        <DxHeaderFilter :visible="true"/>
                    </DxDataGrid>
                    </div>
                    
                    </div>
                  </DxScrollView> 
                
        </DxPopup>
 <div class="table"> 
  <div class="clNadpisAktualniStranky" height="50px">
            <h2 id="MajetekHeader" class="nadpisH2Moduly prevent-select">{{ $t('majetek') }}</h2> 
        </div>

  <div  class="container" style="display: inline-flex; margin-bottom: 10px;">
    <div style="margin-left: 20px; margin-right:40px" @mouseover="getTitle(zamestnanci,zamestnanec)" v-bind:title="getTitle(zamestnanci,zamestnanec)">
      <DxSelectBox class="selectboxZam" :disabled="checked" :id="2" height="45px" width="350px" :label="$t('pozadavky-Zamestnanec')" @initialized="GetEmployeesInit($event)"
          :data-source="zamestnanci" v-model="zamestnanec" display-expr="content" value-expr="id" :value="ValueZam" @closed="SetValue"
          :placeholder="$t('central-Placeholder')" @value-changed="setSelectedValue($event)" :search-enabled="true" @opened="ClearValue" 
            @input="SearchInputEMP" noDataText="" >
      </DxSelectBox>                                    
    </div>
    <div v-if="zamestnanci.length>1" class="inlineflex filterBar childdivHorniLista checkboxContainer">
      <DxCheckBox class="checkPouzePlatne" v-model:value="checked" :text="$t('majetek-Filtr')" @value-changed="CheckBoxChanged" height="45px" width="auto" ></DxCheckBox>
    </div>
    
  </div>
  <br> 
      <i>{{ $t('majetek-Popisek') }}</i>
      <div id="MajetekHorniCast" v-if="false">    
      <DxButton icon="user" height="45px" :text="$t('majetek-PrevodMajetku')" styling-mode="outlined" type="success" id="prevodButton" class="prevodID" 
        @click="this.$router.push('/Prevodmajetku')">
      </DxButton>
      <DxButton icon="close" height="45px" :text="$t('majetek-VyrazeniMajetku')" styling-mode="outlined" type="danger" id="vyrazeniButton" class="vyrazeniID"
        @click="this.$router.push('/Vyrazenimajetku')">
      </DxButton>
    </div>
    <hr class="hrModuly">
    <div class="bordersDataGridPozadavkyModuly">
      <DxDataGrid column-resizing-mode="widget" :allow-column-resizing="true"  :column-auto-width="true" :hoverStateEnabled="true" :rowAlternationEnabled="true"
        ref="mygrid" id="DataGridMajetek" :data-source="GridRows" :allow-column-reordering="true" :show-borders="true" :height="(vyskaZobrazitelna/1.5)" width="100%"
        key-expr="id" @cell-click="ShowDetail"  :noDataText="$t('central-NenalezenyZadneZaznamy')" @option-changed="SearchInput"
        :editing-texts="{ saveRowChanges: '*SAVE*', cancelRowChanges: '*CANCEL*',}" @cellPrepared="onCellPrepared" 
      @exporting="onExporting" >
      <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
      <DxScrolling mode="standard" />
      <DxGrouping :auto-expand-all="true" width="50px"/>
      <!-- <DxSelection mode="multiple"/> -->  
        <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :allow-export-selected-data="false"
          :texts="{exportAll: $t('central-ExportVseExcel')}"
          />
          <DxToolbar >
            <DxItem location="before" locate-in-menu="auto"
                                        name="groupPanel"
              />
              
              
              <DxItem location="after" locate-in-menu="auto" widget="dxButton">
                  <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                  type="success" style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined" @click="GridLayoutReset('mygrid')" />      
              </DxItem>
              <DxItem location="after" locate-in-menu="auto"
              name="exportButton"
              />
              <DxItem location="after" locate-in-menu="auto"
              name="searchPanel"
              :visible="true"
              />                         
            </DxToolbar>    
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="GridMajetek"
         />
         <template #vyrazeni="{ data }" >
                      <img v-if="data.data.vyrazeni" src="@/assets/images/AssetDeleteRedT.png" width="25" height="25" @click="RedirectVyrazeni(data.data)">
                          
                      <span v-else style="height:50px" class="dx-icon-lock" icon="lock" width="50" height="50"></span>
        </template>
         <DxColumn cell-template="vyrazeni" width="100px" data-field="vyrazeni" :caption="$t('majetek-Vyrazeni')" :allow-editing="false" :visible="!checked && !zamSelected" :formItem="{ visible: false }" />
         <DxColumn width="200px" data-field="zam" :caption="$t('pozadavky-Zamestnanec')" :allow-editing="false" :visible="checked" :formItem="{ visible: false }" />
         <DxColumn width="125px" data-field="id" :caption="$t('majetek-InvCislo')" :allow-editing="false" :visible="true" :formItem="{ visible: false }" />
        <DxColumn  width="350px" data-field="name" :caption="$t('majetek-Nazev')">
        </DxColumn>
        <DxColumn format=",##0.00" width="170px" data-field="price" :caption="$t('majetek-PorizovaciCena')">
        </DxColumn> 
        <DxColumn alignment="right" width="130px" data-field="date" data-type="date" :caption="$t('majetek-Zarazeni')" format="dd. MM. yyyy">        
        </DxColumn>
             
         <DxColumn  data-field="internalid" caption="interní id" :visible="false" :formItem="{ visible: false }" >        
        </DxColumn>     
        <DxColumn width="150px" data-field="locked"  data-type="boolean" :caption="$t('majetek-Blokovan')" :visible="false"  :formItem="{ visible: false }"  >        
        </DxColumn>
            <DxColumn  data-field="place" :caption = "$t('majetek-Mistnost')" :visible="false" >
            </DxColumn>
            <DxColumn  data-field="note" :caption="$t('majetek-Poznamka')" :visible="false">
            </DxColumn>
            <DxColumn  data-field="grant" :caption="$t('majetek-Grant')" :visible="false">
            </DxColumn>
            <DxColumn  data-field="group" :caption="$t('majetek-Skupina')" :visible="false">
            </DxColumn>
            <DxColumn  data-field="evidencedocument" :caption="$t('majetek-DokladZarazeni')" :visible="false">
            </DxColumn>
            <DxColumn  data-field="department" :caption="$t('majetek-Obor')" :visible="false">
            </DxColumn>  
        <DxEditing :allow-updating="false" :allow-deleting="false" :allow-adding="false" mode="form" editRow="Upravit"
          :texts="{
          saveRowChanges: 'Povrdit', cancelRowChanges: 'Zrušit', editRow: '', deleteRow: 'Smazat',
          confirmDeleteTitle: 'Varování', confirmDeleteMessage: 'Opravdu si přejete smazat tento záznam?', }" />
        <DxPaging :enabled="false" />
        <DxGroupPanel :visible="true" :empty-panel-text="$t('majetek-PretahneteSemZahlavi')" />
        <DxGrouping :auto-expand-all="true" />
        <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
        <DxSearchPanel id='searchpanelMajetekGrid' :width="150" :visible="true" :placeholder="$t('majetek-Hledat')" />
        <DxFilterRow :visible="true"/>
                    <DxFilterPanel :visible="true" :texts="{
                      clearFilter: $t('central-ZrusitFiltr'), createFilter: $t('central-VytvoritFiltr'), filterEnabledHint: $t('central-FiltrVysvetlivka'), saveChanges: 'Povrdit',
                    }"/>
                    <DxFilterBuilderPopup  value="text" />
                    <DxHeaderFilter :visible="true"/>
</DxDataGrid>
      <br>
      <DxButton icon="chevrondown" type="button" height="auto" width="auto" :text="$t('majetek-NacistDalsiZaznamy')" class="button4 buttonsDataGridSpodniLista"
        v-on:click="Button4Click()" :disabled="disable" />
        <DxButton icon="arrowdown" type="button" height="auto" width="auto" :text="$t('central-NacistVse')" class="button4 buttonsDataGridSpodniLista" 
        v-on:click="ButtonAllClick()" :disabled="disable" />
      <br>
    </div>
    </div>

</div>

</template>
<script>
const font = '';
const optionsDate = {
    dateStyle: 'date'
    //timeStyle: "long"   
}
import notify from 'devextreme/ui/notify';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid as exportDataGridToExcel} from 'devextreme/excel_exporter';
import {DxExport, DxStateStoring, DxLoadPanel,        DxHeaderFilter,
        DxFilterRow,
        DxFilterPanel,
        DxFilterBuilderPopup,
        DxToolbar,
        DxSorting
} from 'devextreme-vue/data-grid';
import DxList from 'devextreme-vue/list';
import DxScrollView from 'devextreme-vue/scroll-view';
import { isNull, isUndefined } from 'lodash';


export default {
    name: 'MajetekView', 
    DxList,  
  data() {     
    
    return {
      Investice: false,
      ValueZam: -666,
      zamestnanci: [{ "content": '', "id": 0 }],
      zamestnanec: 0,
      search: "",
      page: 1,
      disable: false,
      msg: this.$t("majetek"),
      columnINVCISLO: this.$t("majetek-InvCislo"),
      columnNAZEV: this.$t("majetek-Nazev"),
      columnDATUMZARAZENI: this.$t("majetek-Zarazeni"),
      columnPORIZOVACICENA: this.$t("majetek-PorizovaciCena"),
      columnBLOKOVANO: this.$t("majetek-Blokovan"),
      zam:'',
      invC:'', 
      vyrC:'',
      misto: '', 
      porizeno: '',
      zarazeno: '',
      porCena: '',
      majetek: { PurchaseDate: '', Placed: '' },  
      GridRows: [],
      GridRowsMovement: [],
      popup: false,
      optionsDate,
      vyskaZobrazitelna:250,
      checked: false,
      zamSelected: false,

      
      };
  },
  computed: {
    myGrid: function () {
      let self = this;
      return self.$refs.mygrid.instance;
    },
    myGridPoh: function () {
      let self = this;
      return self.$refs.mygridpoh.instance;
    },
  },unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
        },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.NewRequest();
    this.DrawerSelected()
    //this.TableData()
    //window.localStorage.setItem("refresh-page", 'Majetek');
    setTimeout(() => {
                    this.$nextTick(() => {
                        this.myEventHandler();
            });}, 1000);
  },
  components: {
    DxExport,
    DxScrollView,
    DxStateStoring,
    DxLoadPanel,
    DxHeaderFilter,
        DxFilterRow,
        DxFilterPanel,
        DxFilterBuilderPopup,
        DxToolbar,
        DxSorting

},
  methods: {
    CheckBoxChanged(e){
      this.$log.debug(e)
      this.disable=false;
      if(e.value) {       
        this.page=0;
        this.disable=true;
        this.zamestnanec=-1;
      }
      else {
        this.zamestnanec=JSON.parse(window.localStorage.getItem("user")).EmployeeID.value ;
        this.page=1;
      }
      
      //this.TableData(); 
    },
    myEventHandler(e){
            this.$log.debug(e);
            this.handleMainViewChange();
            this.handlePopupChange();
          },
    handleMainViewChange(){
          //  console.log("handleMainViewChange");
                let self = this;
                var element = document.getElementById("ScrollViewAPP");
                    if (element) {
                 //       console.log(element);
                        var height = element.clientHeight;
                        if ((height<500) && (height>300))  {height=550} 
                        if (height<=300) {height=450} 
                        self.vyskaZobrazitelna = height;
                        
        
          //  console.log("handleMainViewChange height:", height);
}
            
        },
        getTitle(datasource,item) {
     //   this.$log.debug('getTitle(item)');
      //  this.$log.debug('item',item,'datasource',datasource);
        var ItemContent = item;
        var LookupArray = datasource;
     //   this.$log.debug('item.DataSource._items',LookupArray,'ItemContent',ItemContent);
        if ((Array.isArray(LookupArray))  && (ItemContent>0))  {
      //    this.$log.debug('ITEM NALEZEN',LookupArray.find(data => data.id == ItemContent))
          var foundItem = LookupArray.find(data => data.id == ItemContent)
          return foundItem ? foundItem.content : undefined;
          } else{
      //      this.$log.debug('item.DataSource._items.length',LookupArray.length)
            return ''
          }
        }, 
        handlePopupChange(){
        //    console.log("handlePopupChange");
                let self = this;
                var elements = document.getElementsByClassName("dx-overlay-content dx-popup-normal dx-popup-draggable dx-resizable");

                    for (var i = 0; i < elements.length; i++) {
                    var element = elements[i];
                    var height = element.clientHeight;

                    if (height > 1) {
                     //   console.log("Element height:", height);
                        if (height < 500 && height > 300) {
                            height = 550;
                        } 
                        if (height <= 300) {
                            height = 450;
                        }

                        self.vyskaDataGridPopup = height;
                   //     console.log(element); 
                        break;
                    }
                    self.vyskaDataGridPopup = 450;
                    }
            
        },
    RedirectVyrazeni(data) {
      this.$log.debug('RedirectVyrazeni',data);
      if (!data || !data.internalid) { // ignorování radek, ktere nejsou s daty
        return;
      } else {
        notify({
          message: this.$t('majetek-PresmerovaniDoVyrazeni') + ' ' + data.id + ' ' + data.name + ' (' + data.date.toLocaleString("cs-CZ", { minimumFractionDigits: 2 }).slice(0, -8) + ')',
          position: {
            my: 'center top',
            at: 'center top',

          },
        }, 'success', 10000); //'info' - barva templatu
        this.$router.push('/Vyrazenimajetku?id=' + data.internalid)
      }
    },
    GetEmployeesInit(e) {
            this.$log.debug(e)
            var self = this;
            this.ValueZam = JSON.parse(window.localStorage.getItem("user")).EmployeeID.value 
            var gen = new window.General(window.channel, window.message);
            self.zamestnanci.length = 0;
            gen.ListEmp(':MYSUB:LIMIT',
                function (result) {
                    //  self.$log.debug(result.fResponseObject.result.Result.List.length)
                    //    self.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        self.zamestnanci[self.zamestnanci.length] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };

                    }
                    e.component.getDataSource().reload();
                    e.component.repaint();
                }, window.this.ApiError());
        },
    GetEmployees(e) {
            this.$log.debug(e)
            var self = this;
            var employee = e.component.option("text")
            var gen = new window.General(window.channel, window.message);  
            self.zamestnanci.length = 0;
            //  self.$log.debug(employee)
            gen.ListEmp(':MYSUB:LIMIT'+employee,
                function (result) {
                    //  self.$log.debug(result.fResponseObject.result.Result.List.length)
                    //    self.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        self.zamestnanci[self.zamestnanci.length] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };

                    }
                    e.component.getDataSource().reload();
                }, window.this.ApiError());
        },
    ClearValue(e) {
            if (e.component.option("text") != '')
                e.component.reset()
        },
        SetValue(e) {
          if (e.component.option("text") == ''){ 
            var self=this
            this.$log.debug(e)
            var gen = new window.General(window.channel, window.message);  
            var EmpID = JSON.parse(window.localStorage.getItem("user")).EmployeeID.value 
            self.zamestnanci.length = 0;
            //  self.$log.debug(employee)
            gen.ListEmp(':MYSUB:LIMIT',
                function (result) {
                    //  self.$log.debug(result.fResponseObject.result.Result.List.length)
                    //    self.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        self.zamestnanci[self.zamestnanci.length] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };

                    }
                    e.component.getDataSource().reload();
                    e.component.option("value", EmpID)    
                    e.component.repaint()
                }, window.this.ApiError());
            
            }
        },
        
        SearchInputEMP(e) {
            let self = this;
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                self.GetEmployees(e)
            }, 1000)

        },
    setSelectedValue(e) {
      this.$log.debug(e)
            switch (e.element.id) {
                case '1':

                    break;
                case '2':
                        
                    this.zamestnanec = e.value 
                    if(e.value!=-1) this.disable=false;
                    if(!isNull(e.value)) {
                      if (e.value!=JSON.parse(window.localStorage.getItem("user")).EmployeeID.value) this.zamSelected=true
                      else this.zamSelected=false
                      this.TableData()
                    }
                 //   this.TableDataPozadavky()                  
                    break;
                case '3':

                    break;
                case '4':
   
                    break;
            }
        },
    NewRequest(){
    window.localStorage.setItem('pozid', '')  
    },
     GridLayoutReset(vstup) {
      switch (vstup) {
                case 'mygrid': this.myGrid.state(null);
                    break;
                case 'mygridpoh': this.myGridPoh.state(null);
                    break;
                
            }   
            //this.$log.debug('GridLayoutReset');
    },
    DrawerSelected() {
      var e = window.Drawer
      if (window.prevElement != undefined && window.prevPosition != undefined)
        window.prevElement.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[window.prevPosition].childNodes[0].style.color = ''
      this.$log.debug(window.Drawer)
      this.$log.debug(this.$route.meta.id)
      this.$log.debug(e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length)
      for (var x = 0; x < e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length; x++) {
        // this.$log.debug(e.element.childNodes[1].childNodes[0].firstChild.childNodes[0].children[0].childNodes[x].attributes[1].value)
        if (this.$route.meta.id == e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].attributes[1].value) {
          e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].childNodes[0].style.color = '#FF9900'
          window.prevElement = e
          window.prevPosition = x
        }
      }
    },
    PozadavkyRedirect(e) {
      window.localStorage.setItem('pozid', '');
      switch (e.element.id) {
        case 'prevod':
           this.$router.push('/Prevodmajetku')
          break;
        case 'vyrazeni':
          this.$router.push('/Vyrazenimajetku')
          break;
      }

    },
    onExporting(e) {
      window.this.$log.debug('test uvnitr onexporting')
    if (e.format==="pdf")  {
        const doc = new jsPDF({ filters: ["ASCIIHexEncode"] });
        
        var callAddFont = function () {
        this.addFileToVFS('GEO_AI__-normal.ttf', font);
        this.addFont('GEO_AI__-normal.ttf', 'GEO_AI__', 'normal');
};
jsPDF.API.events.push(['addFonts', callAddFont])

        

        exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component,
          indent: 0,
          
          
          columnWidths: [30, 50, 22, 30,0],
          

          customizeCell: function(options) {
            const { gridCell, pdfCell } = options;
          
                      if(gridCell.rowType === 'data') {
                        pdfCell.font = { size: 13, name:'Courier'};
                        pdfCell.padding = { top: 1, right: 1, bottom: 1, left: 1 };
                        pdfCell.wordWrapEnabled=true;
                        pdfCell.textColor='#000000' 
                      }
                      if(gridCell.rowType === 'data' && gridCell.column.dataField === this.columnBLOKOVANO){
                        pdfCell.textColor='#ffffff'

                      }
                                      
           
         
          
          }
        }).then(() => {
          
          const datum= new Date();
          doc.save(this.msg+" "+datum.toISOString('cs-CZ').substring(5, 10)+" "+datum.toTimeString('cs-CZ').substring(0, 9)+".pdf");
        });
        e.cancel = true;
      }
      else if (e.format==="xlsx") {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Companies");
        exportDataGridToExcel({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
          customizeCell: function(options) {
                    const excelCell = options;
                    excelCell.font = { name: 'Arial', size: 12 };
                    excelCell.alignment = { horizontal: 'left' };
                } 
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            const datum= new Date();
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              this.msg+" "+datum.toISOString('cs-CZ').substring(5, 10)+" "+datum.toTimeString('cs-CZ').substring(0, 9)+".xlsx"
            );
          });
        });
        e.cancel = true;
      }
    
    
    
    },
    onCellPrepared(e) {
      var self = this
      if (!e.value && e.rowType == "data" && e.column.dataField == 'vyrazeni') {
    //    window.this.$log.debug(e)
        e.component.columnOption('vyrazeni','width',130)
        e.cellElement.addEventListener('mouseover', function () {
          const headerTitle = e.cellElement.querySelector('.dx-icon-lock');
            headerTitle.setAttribute("title", self.$t('majetek-MajetekSoucastiPozadavku'));
            if (headerTitle.scrollWidth > headerTitle.clientWidth) {
              headerTitle.setAttribute("title", "Minimální cena");
            }  
        });
      }

    },
    SearchDelay(search) {
      let self = this;
      clearTimeout(this.timer)
      // window.this.$log.debug(search)
      this.timer = setTimeout(() => {
        self.search = search
        self.page = 0
        self.disable = true
        self.TableData();
        
      }, 1000)

    },

    SearchInput: function (e) {
    //  window.this.$log.debug(e)

      if (e.name === "searchPanel") {
        this.searched = false
        window.this.$log.debug(e.value)
        if (e.fullName != 'searchPanel.placeholder')
          this.SearchDelay(e.value)
      }
      else {

        // this.Detail(params.row.id_vydejky);

      }
    },
    Button4Click() {
      this.page++;
     // let self = this;
      this.TableData();
    },
    ButtonAllClick() {
      this.page=0;
      this.disable=true;
      this.TableData();
    },
    ShowDetail(e) {
      window.this.$log.debug(e)
    setTimeout(() => { // kvuli oznacovani aby se hned neotevrel popup
      const selection = window.getSelection();
            if (selection && selection.toString().length > 0) {
                // User is selecting text; do not proceed
                return;
            }
      if (e.column != undefined && e.column.dataField != 'vyrazeni' && e.columnIndex != 0 && e.rowType != 'header' && e.rowType != 'filter' ) {
        
        var asset = new window.Asset(window.channel, window.message);
        var self = this;
        self.GridRowsMovement.length=0
          asset.GetAssetDetail(e.data.internalid,
            function (result) {
              window.this.$log.debug(result)
              self.majetek.Name = result.Name.value
              self.majetek.Zam = result.OwnerName.value
              self.majetek.InvC =  result.Id.value
              self.majetek.VyrC = result.SerialNumber.value
              self.majetek.PorCena = result.Value.value.toLocaleString("cs-CZ", { minimumFractionDigits: 2})
              self.majetek.Place = e.row.data.place
              self.majetek.Note = e.row.data.note
              self.majetek.Grant = e.row.data.grant
              self.majetek.Group = e.row.data.group
              self.majetek.EvidenceDocument = e.row.data.evidencedocument
              self.majetek.Department = e.row.data.department
              self.Investice = !self.majetek.InvC.search("I")
              self.$log.debug(self.Investice)
              // DATUMY INEVIDENCE A PLACED JSOU Z API PROHOZENE !
              if (isNull(e.row.data.inevidence) || isUndefined(e.row.data.inevidence)) { window.this.$log.debug('1') }
              else self.majetek.PurchaseDate = e.row.data.inevidence.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
              if (isNull(e.row.data.date) || isUndefined(e.row.data.date)) { window.this.$log.debug('1') }
              else self.majetek.Placed = e.row.data.date.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
              for (var x = 0; x < result.History.value.items.length; x++) {
              window.this.$log.debug(x)
                var i = self.GridRowsMovement.length;
              window.this.$log.debug(self.GridRowsMovement)
              self.GridRowsMovement[i] = {
                movement: result.History.value.items[x].HistoryType.value,
                date: result.History.value.items[x].Date.value,
                document: result.History.value.items[x].Document.value,
                price: result.History.value.items[x].Price.value,
                description: result.History.value.items[x].Description.value,
                empfrom: result.History.value.items[x].FromEmp.value,
                empto: result.History.value.items[x].ToEmp.value
                };
              
              }
           
            window.this.$log.debug('predpopupem')
              self.popup = true;   
            }, window.this.ApiError());
      }
      else if (e.column != undefined && !e.value && e.columnIndex != 0 && e.rowType != 'header' && e.rowType != 'filter' ) {
        notify({
          message: this.$t('majetek-MajetekNelzeVyradit'),
          position: {
            my: 'center top',
            at: 'center top',

          },
        }, 'warning', 5000); //'info' - barva templatu

      }
    }, 0);  
    },
    RefreshDataGrid() { // dat pozor, aby tu nebyl nejaky vypis, ktery vytvori chybu a zbytek funkce se pak neprovede
      this.$log.debug('refresh')
      this.myGrid.getDataSource().reload() 

    },
    open() {
        window.loader = this.$loading.show({ loader: 'dots' });
    },
    hide() {
        window.loader.hide()
    },
    TableData() {
    var asset = new window.Asset(window.channel, window.message);
        let self = this;
      if (this.page <= 1) {
        window.this.$log.debug('nulovani')
        self.GridRows.length = 0;
      } 
  //  this.open();
      asset.GetAsset(self.zamestnanec, true, this.search, this.page,15,
        function (result) {
        window.this.$log.debug(result);
          if (result.List.value.items.length == 0 || result.List.value.items.length < 15 || self.disable==true ) {
            self.disable = true;
          }
          else self.disable==false
        for ( var x = 0; x < result.List.value.items.length; x++) {

            var i = self.GridRows.length;
            self.GridRows[i] = {  
                id: result.List.value.items[x].Id.value,
                name: result.List.value.items[x].Name.value,
                price: result.List.value.items[x].Price.value, 
                date: result.List.value.items[x].Inevidence.value,
                inevidence: result.List.value.items[x].From.value,
                internalid: result.List.value.items[x].InternalId.value,               
                locked: result.List.value.items[x].Locked.value,  
                vyrazeni: !result.List.value.items[x].Locked.value,
                place: result.List.value.items[x].Place.value,
                note: result.List.value.items[x].Note.value,
                grant: result.List.value.items[x].Grant.value,
                group: result.List.value.items[x].Group.value,
                evidencedocument: result.List.value.items[x].EvidenceDocument.value,
                department: result.List.value.items[x].Department.value,
                zam: result.List.value.items[x].Zam.value
                
                                     
            };                   
        }
        self.RefreshDataGrid()
       // self.hide();
        }, window.this.ApiError());

    },
  },

}
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .selectboxZam {
    width: 100%  !important; 
    margin-right: 0; 
  }
  .container {
    flex-wrap: wrap; 
  }

  .checkboxContainer {
    margin-top: 10px; 
    margin-left: 20px; 
  }
}
.dx-icon-lock {
    font: 30px/1 DXIcons;
}
  #buttonLoadMoreMajetek,#buttonLoadAllMajetek{
    padding:0px; margin: 5px;
  }
  #popup{
   background-color: rgb(red);
   text-align: center;
  }
  #MajetekHorniCast{
    display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
    padding: 0px 0px 0px;
    width: 100%; 
  }
  #MajetekHeader{
    width: 100%;
    text-align: center;
    /*background-color: aquamarine;*/
    padding: 0px;
    order:1;
    
    
  }
  #prevodButton{
    
    border-bottom: 4px solid green;
    margin-bottom: 10px;
    float: left;
    order:2;
   
  }
  #vyrazeniButton{
    order:3;
    border-bottom: 4px solid red;
    text-align: left;
    flex-grow: 0;
  }
  .popupHeaderMajetek{
    vertical-align: center;
    text-align: left;
    margin-bottom: 3px;
    color: rgb(83, 83, 83);
    margin-left: 0px;
    margin-right: 10px;
    margin-top: 0px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 25px;
    font-weight: 500;
    font-size: small;
    
    

  }
  .popupMajetekItemInfo{
    
    width: auto;
    background-color: white;
    height: 36px; 
    border-color: black;
    border-style: ridge;
    margin-left: 0;
    margin-right: 10px;
    border-radius: 8px;
    border-width: 0.5px;
    margin-bottom: 00px;
    background-color: white;
    
  }
  #popupMajetekItemInfoID{
    margin-bottom: 0px;
    height: max-content;
  }
  .popupObsahMajetek{
    display: flex;
    text-align: center;
    
    height: auto;
    width: inherit;
    color: black !important;
   align-self: center;
    text-overflow: hide;
    text-align: center;
    margin-left: 0;
    word-wrap:break-word;
    line-height: 150%;
    font-size: medium;
    
    padding: 5px;
    
  }
  #DateboxBottom{
    display: flex;
    text-align: center;
    margin-top: 250px;
border-style: ridge;
border-width: 2px;
border-left: 0;
border-right: 0;
border-bottom: 0;
padding-top: 8px;

    
    
    
  }
  #PuchaseTimeDateBox{
   height: max-content;


   
  }
  #popupMajetekInfoLEFT{
float: left;
display: inline;
width: max-content;
border-color: rgba(128, 128, 128, 0.2);
border-style: ridge;

border-width: 0.5mm;
margin-right: 10px;
background-color: white;
padding-left: 10px;
padding-right: 10px;
padding-top: 10px;
padding-bottom: 10px;
background-color: white;





  }
  #popupMajetekInfoRIGHT{
    float: left;
display: inline;
width: max-content;
border-color: rgba(128, 128, 128, 0.2);
border-style: ridge;

border-width: 0.5mm;
margin-right: 10px;
background-color: white;
padding-left: 10px;
padding-right: 10px;
padding-top: 10px;
padding-bottom: 10px;
  }
  #DataGridCely{
    border-style: ridge;
    border-color: rgba(128, 128, 128, 0.226);
    border-radius: 10px;
    padding: 10px;
    height: 100%;
   
  }

</style>