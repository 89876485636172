<template>
  <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000713627?lang=cs" 
  URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000713627?lang=en" 
PopisLinku='clanky.pruvodniListSmlouva'></HelpLink>
  <div height="calc(100% - 57px)">
        <DxPopup id="popup2" v-model:visible="popupAlert" :show-title="true" :drag-enabled="false"
                :hide-on-outside-click="true" :show-close-button="true" :title="$t('central-Varovani')" 
                :contentTemplate="popupAlertTemplate" :width="400" :height="200">
                <template #SmazatPoz> 
                    <h3>{{$t('majetekVyrazeni-OpravduSiPrejeteSmazat')}}</h3>                   
                  <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained" :text="$t('central-Ano')"
                    @click="SetState(-99, true); popupAlert=false " />
                  <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained" :text="$t('central-Ne')"
                    @click="popupAlert=false" />
                </template>
        </DxPopup>
        
        <div id="horniListaPozadavku">
          <DxButton icon="back" :text="$t('majetekVyrazeni-VratitSeZpet')" height="auto" type="outlined" styling-mode="contained" id="link" 
        @click="$router.push('Pozadavky')" />
        <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " v-if="zakladani">{{ $t('Smlouvy-VytvoreniSmluv')}}</h2>
        <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " v-else>{{$t('Smlouvy-PruvodniList')}}{{ poz +' ('+state+')' }} </h2>
        </div>
        <form action="your-action" @submit="onFormSubmit($event)">
          <div id="obaleniFormulare">
            <!--
              "Smlouvy-VytvoreniSmluv":"Založení průvodního listu ke smlouvě",
	"Smlouvy-PruvodniList":"Průvodní list ke smlouvě: ",
	"Smlouvy-NazevSmlouvy":"Název smlouvy",
	"Smlouvy-PredmetSmlouvy":"Předmět smlouvy",
	"Smlouvy-FinancniPlneni":"Finanční plnění",
	"Smlouvy-TypSmlouvy":"Typ smlouvy",
	"Smlouvy-Oblast":"Oblast",
	"Smlouvy-Referent":"Referent",
	"Smlouvy-Zverejnit":"Zveřejnit",
  "Smlouvy-VyplnenNazev": "Contract name must be filled.",
	"Smlouvy-VyplnenPredmet": "Contract subject must be filled.",
	"Smlouvy-VyplnenTypSmlouvy": "Contract type must be filled.",
	"Smlouvy-VyplnenaOblast": "Contract region must be filled.",
	"Smlouvy-VyplnenoFinancniPlneni": "Contract price must be filled.",
            -->
<div id="obalenigrid">
          <DxResponsiveBox single-column-screen="sm" id="SmlouvaResponsiveBox">
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxItem v-if="false">
              <DxLocation :row="1" :col="0" :colspan="3" screen="lg" />
              <DxLocation :row="1" :col="0" :colspan="3" screen="md" />
              <DxLocation :row="1" :col="0" :colspan="3" screen="sm" />
              <DxLocation :row="1" :col="0" :colspan="3" screen="xs" />
                <div class="SmlouvaBox">
                  <Employees :Disabled=" stateid != 4 " :EmpId=" formData.referent " @value-changed=" setSelectedValue " :Label=" $t('Smlouvy-Referent') " width="auto" />
                </div> 

            </DxItem>

            <DxItem>
              <DxLocation :row="1" :col="0" :colspan="3" screen="lg" />
              <DxLocation :row="1" :col="0" :colspan="3" screen="md" />
              <DxLocation :row="1" :col="0" :colspan="3" screen="sm" />
              <DxLocation :row="1" :col="0" :colspan="3" screen="xs" />
             
              <div class="SmlouvaBox">
                <div class="dx-field">
                  <DxTextBox :disabled="stateid!=4" :id="1" width='auto' height="45px" :label="$t('Smlouvy-NazevSmlouvy')" :maxLength="150"
                     placeholder="" @value-changed="setSelectedValue" :value=" formData.nazev " v-model:value=" formData.nazev ">
                    <DxValidator @validated="handleValidation">
                      <DxRequiredRule :message="$t('Smlouvy-VyplnenNazev')" />
                    </DxValidator>
                  </DxTextBox>
 
                </div>
              </div>
            </DxItem>

            <DxItem width="400px">
              <DxLocation :row="2" :col="0" :colspan="3" screen="lg" />
              <DxLocation :row="2" :col="0" :colspan="3" screen="md" />
              <DxLocation :row="3" :col="0" :colspan="3" screen="sm" />
              <DxLocation :row="3" :col="0" :colspan="3" screen="xs" />
              
              <div class="SmlouvaBox" width='auto'>
                        <Suppliers :Disabled=" stateid != 4 " :Supplier=" supplier " @value-changed=" setSelectedValue " Width='auto' :Label="$t('Smlouvy-Protistrana')"   />  
              </div>
            </DxItem>
            
            <DxItem>
                <DxLocation :row=" 5 " :col=" 0 " :colspan=" 3 " screen="lg" />
                <DxLocation :row=" 5 " :col=" 0 " :colspan=" 3 " screen="md" />
                <DxLocation :row=" 6 " :col=" 0 " :colspan=" 3 " screen="sm" />
                <DxLocation :row=" 6 " :col=" 0 " :colspan=" 3 " screen="xs" />
                <div class="SmlouvaBox">
                    <DxTextArea style="margin-bottom: 0px;"  :disabled=" stateid != 4 " :id=" 2 " height="100px" :label=" $t('Smlouvy-PredmetSmlouvy') " 
                       placeholder="" @value-changed=" setSelectedValue " :value=" formData.predmet ">
                      <DxValidator @validated="handleValidation">
                        <DxRequiredRule :message=" $t('Smlouvy-VyplnenPredmet') " />
                      </DxValidator>
                    </DxTextArea>
                </div>
              </DxItem>

              <DxItem>
                  <DxLocation :row=" 3 " :col=" 0 " :colspan=" 3 " screen="lg" />
                  <DxLocation :row=" 3 " :col=" 0 " :colspan="3 " screen="md" />
                  <DxLocation :row=" 4 " :col=" 0 " :colspan=" 3 " screen="sm" />
                  <DxLocation :row=" 4 " :col=" 0 " :colspan=" 3 " screen="xs" />
                  <div class="SmlouvaBox"> 
                    <DxSelectBox :disabled=" stateid != 4 "  :id=" 4 " width="auto" height="45px" :label=" $t('Smlouvy-TypSmlouvy') "
                              :data-source=" smlouvyTypy " display-expr="content" value-expr="id" placeholder="" :search-enabled="true"
                            :value=" formData.typSmlouvy " @value-changed=" setSelectedValue($event); " >
                            <DxValidator @validated="handleValidation">
                              <DxRequiredRule :message=" $t('Smlouvy-VyplnenTypSmlouvy') " />
                            </DxValidator>
                      </DxSelectBox>
                  </div>
                </DxItem>

                  <DxItem>
                    <DxLocation :row=" 6 " :col=" 0 " :colspan=" 3 " screen="lg" />
                    <DxLocation :row=" 6 " :col=" 0 " :colspan=" 3 " screen="md" />
                    <DxLocation :row=" 7 " :col=" 0 " :colspan=" 3 " screen="sm" />
                    <DxLocation :row=" 7 " :col=" 0 " :colspan=" 3 " screen="xs" />
                    <div class="SmlouvaBox">
                        <DxNumberBox height="45px" :disabled=" stateid != 4 " :id=" 3 " :label=" $t('Smlouvy-FinancniPlneni') "
                           placeholder="" @value-changed=" setSelectedValue " :value=" formData.cena " style="display: inline-flexbox;"
                           :format="{
                             type: 'fixedPoint',
                             precision: 2,
                             thousandSeparator: ' ',
                             decimalSeparator: ','}" :step="0">
                     
                          <DxValidator @validated="handleValidation">
                            <DxRequiredRule :message=" $t('Smlouvy-VyplnenoFinancniPlneni') " />
                            <DxCustomRule
                                                :validation-callback="validateNumberHigherThanZERO"
                                                :message="this.$t('Validator.CenaMusiBytVyssiNezNula')"
                                                :reevaluate="true"
                                            />
                                            <DxRangeRule
                              :min="$decimalLimits.min"
                              :max="999999999999.99"
                              :message="localizedMessage"
                            />
                          </DxValidator>
                        </DxNumberBox> 
                      </div>
                    
                  </DxItem>
                  <DxItem>
                    <DxLocation :row=" 7 " :col=" 0 " :colspan=" 3 " screen="lg" />
                    <DxLocation :row=" 7 " :col=" 0 " :colspan=" 3 " screen="md" />
                    <DxLocation :row=" 8 " :col=" 0 " :colspan="3 " screen="sm" />
                    <DxLocation :row=" 8 " :col=" 0 " :colspan=" 3 " screen="xs" />
                    <div class="SmlouvaBox">
                       

                     <DxCheckBox class="clCheckBoxForma " style="padding: 5px;" :disabled="  stateid != 4 " :text="$t('Smlouvy-Podpis')+' '" height="45px" :id="7" v-model:value=" formData.zverejnit " @value-changed=" setSelectedValue " />
                    </div> 
                  </DxItem> 
                  <DxItem>
                    <DxLocation :row=" 8 " :col=" 0 " :colspan=" 3 " screen="lg" />
                    <DxLocation :row=" 8 " :col=" 0 " :colspan=" 3 " screen="md" />
                    <DxLocation :row=" 9 " :col=" 0 " :colspan="3 " screen="sm" />
                    <DxLocation :row=" 9 " :col=" 0 " :colspan=" 3 " screen="xs" />
                    <div class="SmlouvaBox">
                      <div class="clCheckBoxForma flexColumn">
                        <label id="smlouvyZpusobPodpis">{{$t('Smlouvy.ZpusobPodpisu')}}</label>
                     <!--<DxCheckBox class="clCheckBoxForma SmlouvaBox" style="padding: 5px;" :disabled="  stateid != 4 " :text="$t('Smlouvy-FyzickyPodpis')" height="45px" :id="8" v-model:value=" formData.podpis " @value-changed=" setSelectedValue " />-->
                     <span class="radio-option">
                        <input type="radio" id="option1" :value="formData.podpis" :checked="formData.podpis === 1" name="signatureType" @change=" setSelectedValueInput($event,1) ">
                        <label for="option1">{{$t('Smlouvy-FyzickyPodpis')}}</label>
                      </span> 
                     <span class="radio-option">
                        <input type="radio" id="option2" :value="!formData.podpis" :checked="formData.podpis === 0"  name="signatureType" @change=" setSelectedValueInput($event,2) ">
                        <label for="option2">{{$t('Smlouvy.ElektronickyPodpis')}}</label>
                      </span>  
                    </div>
                    </div> 
                  </DxItem>
                           
            </DxResponsiveBox>
          </div>
            </div>
        <div id="obaleniFormulare2" padding-top="5px" v-if="!zakladani ">          
          <DxResponsiveBox single-column-screen="sm" id="DruhyResponsiveBox" class="ResponsiveBoxClass" width="100%">
            <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="0.5" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
          <DxItem>
            <DxLocation :row="0" :col="3" :colspan="3" screen="lg" />
                        <DxLocation :row="0" :col="3" :colspan="3" screen="md" />
                        <DxLocation :row="1" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="1" :col="0" :colspan="6" screen="xs" />       
            <div>
                      <Attachments :Disabled=" stateid != 4 " :Pozid="this.poz" :FileUploadFileType="1" :ListFilesfileType="1" :FileDeleteFileType="1"/>
                  </div> 

            </DxItem>

            <DxItem>
              <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="xs" />
              <div class="dx-field" v-if="!zakladani">
                
                <DxTextArea class="DxSelectBoxResponsive" :disabled="stateid!=4" :id="6" :label="$t('majetekPrevod-Komentar')" width="auto" :height="100" @value-changed="  setSelectedValue " v-model:value="formData.komentar"
                  placeholder="" />
              </div>
            </DxItem>
          </DxResponsiveBox>
        </div>
          <DxResponsiveBox single-column-screen="sm"  class="SpodniMenuDetailPozadavku">
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />

              <DxItem >
                <DxLocation :row="0" :col="0" :colspan="3" screen="lg" />
                <DxLocation :row="0" :col="0" :colspan="3" screen="md" />
                <DxLocation :row="0" :col="0" :colspan="5" screen="sm" />
                <DxLocation :row="0" :col="0" :colspan="5" screen="xs" />
                <div id="ResponsiveBoxItemActionButtons" v-if=" zakladani ">
                      <DxButton icon="add" id="vytvorit" height="auto" width="auto" :text="$t('Smlouvy-ZalozitPruvodniList')" :use-submit-behavior="true"
                        type="success"  />
                
                      </div>
              </DxItem>

              <DxItem >
                <DxLocation :row="1" :col="0" :colspan="5" screen="lg" />
                <DxLocation :row="1" :col="0" :colspan="5" screen="md" />
                <DxLocation :row="1" :col="0" :colspan="5" screen="sm" />
                <DxLocation :row="1" :col="0" :colspan="5" screen="xs" />
                <DxScrollView direction="horizontal" showScrollbar="always" width="100%" v-if="!zakladani">
                  <div id="ResponsiveBoxItemActionButtons" v-if="!zakladani ">
                    <div class="child actionButtonsPozadavkyMargin" v-for="item in actionsPozadavky" :key="item" id="actionbuttons">
                      <DxButton class="button1 actionButtonsPozadavkyResizableA" height="100%" width="auto" type="default" styling-mode="contained"
                        :text="item.nazev" :id="item.id" @click="SetState(item.id)" :class="item.class" :icon="item.icon"
                        v-show="(item.nazev != 'Schválit') && (item.nazev != 'Vrátit') && (item.nazev != 'Zamítnout')" />
                    </div>
                    
                    <div class="child" >
                        <ChangeOwner :Disabled="false" :RequestID="poz" @owner-changed="setSelectedValue" >  </ChangeOwner>
                        </div>
                  </div>
                 </DxScrollView>
                  
              </DxItem>
          </DxResponsiveBox>
        
        </form>
        
  
  
 </div>   

</template>

<script>
//import axios from 'axios'
import ChangeOwner from '@/components/ChangeOwner.vue';
import notify from 'devextreme/ui/notify';
import Employees from '@/components/Employees.vue';
import Suppliers from '@/components/Suppliers.vue';
import Attachments from '@/components/Attachments.vue';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import HelpLink from '@/components/HelpLink.vue';
import {
  DxValidator,
  DxRequiredRule,
  DxRangeRule,
  DxCustomRule,
} from 'devextreme-vue/validator';
import { isNull, isUndefined } from 'lodash';
import DxScrollView from 'devextreme-vue/scroll-view';
export default {
  name: "SmlouvaView",

  components: {
    Employees,
    Suppliers,
    Attachments,
    DxTextBox,
    DxNumberBox,
    ChangeOwner,
    HelpLink,
    DxValidator,
    DxCustomRule,
    DxScrollView,
    DxRequiredRule,
    DxRangeRule,
},

  data() {
    return {   
            //  pattern: /^\d{}/i,
      formData: {
        nazev: "",
        cena: null,
        predmet: '',
        zverejnit: false,
        referent: 0,
        protistrana: [{ content: '', id: -1 }],
        protistranaTXT: "",
        komentar: '',
        typSmlouvy: null,
        podpis: undefined,
              
      },
      smlouvyTypy: [],    
      PocetKolonek: 7,  // kolikrat se zavola setselectedvalue, pokud je komentar vyplneny, dava se PocetKolonek++
      MountingData: false,
      stateid: 4,
      state: 'Uložený',
      popupAlert: false,
      popupAlertTemplate: '',
        actionsPozadavky: [],
        Via:[{ content: '', id: -1 }],
        komentar:'',
        suppliers: [],
        supplier: [{ content: '', id: -1 }],
        vias: [],          
        poz: 0,
        zakladani: true,
        selectedOption: null,
        hasValidationError: false,
               
    }
  },
  computed: {
    localizedMessage() {
        if (window.localStorage.getItem("jazyk") == 'en')
        { 
          return (
            this.$t('pozadavky-HodnotaMezi') +
            ' ' +
            this.$decimalLimits.min.toLocaleString('en-US', { minimumFractionDigits: 2 }) +
            ' — ' +
            this.$decimalLimits.max.toLocaleString('en-US', { minimumFractionDigits: 2 })
          );
        }
        else {
          return (
          this.$t('pozadavky-HodnotaMezi') +
          ' ' +
          this.$decimalLimits.min.toLocaleString('cs-CZ', { minimumFractionDigits: 2 }) +
          ' — ' +
          this.$decimalLimits.max.toLocaleString('cs-CZ', { minimumFractionDigits: 2 })
          );
        } 
    },
  },
  watch: {

  },
  created(){
    window.MountCount = 0
  },
  mounted() {
    this.TypySmlouvy();
    this.ListVias();
    this.MountingData = true
    if (window.localStorage.getItem("pozid") != '' && window.history.state.back!=null) {
      this.zakladani = false
      this.poz = window.localStorage.getItem("pozid")
      this.DisplayRequest();

    }
    this.LoadBarvyTheme();
 /*  
    var wh = new window.WorkFlow(window.channel, window.message);
      wh.GetAdditionalFields(2526647,
        function (result) {
          window.this.$log.debug(result);
        }, window.this.ApiError());
        
         wh.SetAdditionalField(2526736,'pozadavky_prac_cesta','cis_pouziti',666,
        function (result) {
          window.this.$log.debug(result);
        }, window.this.ApiError());
     */   

  },

  methods: {
    handleValidation(e) {
        this.$log.debug(e)
        this.hasValidationError = !e.isValid;
    },
    LoadBarvyTheme(){
            var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
    var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
    var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
    var rgbaColorTheme = FillColorBarvaTheme.replace("rgb", "rgba").replace(")", ", 0.25)");
        //console.log('Background Color:', FillColor);
        document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
        document.documentElement.style.setProperty('--colorByThemeQuarterOpacity', rgbaColorTheme);
        },
    SetState(stav, alert) {
      window.this.$log.debug(alert + ' alert<<<??>')
      let self = this;
      var wh = new window.WorkFlow(window.channel, window.message);

      if (stav == -1) {
        window.this.$log.debug('konec')
        this.popupAlert = true
        this.popupAlertTemplate = 'SmazatPoz'
        return 1;
      }
      if (alert == true) stav = -1
     // self.open();
      wh.SetState(self.poz, stav, '',
        function (result) {
          window.this.$log.debug(result);
          if (result.ErrorNum.value != 0) {
            notify({
              message: result.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
          }
          else {
            self.DisplayRequest();
            if (result.ErrorNum.value == 0 && (stav == -1 || stav == 0 || stav == 4)) self.$router.push('/Pozadavky?Vypis=0')

            var stavText;
            switch (stav) {
              case -1: stavText = self.$t('pozadavky-StavZrusen')
                break;
              case 0: stavText = self.$t('pozadavky-StavKeSchvaleni')
                break;
              case 4: stavText = self.$t('pozadavky-StavUlozeny')
                break;
            }
            notify({
              message: self.$t('pozadavky-UvedenDoStavu') + stavText + '".',
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'success', 5000);
          }
       //   self.hide();
        }, window.this.ApiError());

    },
    validateNumberHigherThanZERO(e){
                    this.$log.debug(e.value+' celkova cena')
                    const isValid = e.value >= 0;
                    //this.validationStatus = isValid ? 'valid' : 'invalid';
                    console.log(isValid)
                    return isValid;
      },
    GetContract(id) {
      let self = this
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.GetContract(id,
        function (result) {
          self.$log.debug(result);
          if (result.Error.value.ErrorNum.value != 0) {
            notify({
              message: result.Error.value.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

          }
          else {

            var resPodpis;
            self.formData.nazev = result.Description.value
            self.formData.cena = result.Price.value
            self.formData.predmet = result.Subject.value
            self.formData.referent = result.Referent.value
           // self.formData.referentTXT = result.ReferentTXT.value
            self.formData.typSmlouvy = result.ContractType.value
            self.formData.komentar = result.Request.value.Comment.value
            if (self.formData.komentar != '') self.PocetKolonek++
            if (result.Post.value != true) self.PocetKolonek--
            self.formData.zverejnit = result.Post.value
            if (result.HandSigned.value != true) self.PocetKolonek--
            if (result.HandSigned.value == true) {resPodpis=1} else {resPodpis=0}
            self.formData.podpis = resPodpis
             self.formData.protistrana = {
              content: result.CounterPartyTXT.value,
              id: result.CounterParty.value,
            };
            self.supplier=self.formData.protistrana
          }
        }, window.this.ApiError());
    },
    ListVias() {
      let self = this;
      self.vias.length = 0;
      var gen = new window.General(window.channel, window.message);
      gen.ListLookup('REQUEST_VIAS[2]', '', 1, 50,
        function (result) {
           window.this.$log.debug(result);
          /*
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self.vias.length;
            self.vias[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
          }
          */
         window.this.$log.debug(self.zakladani);
          if (!self.zakladani) self.GetContract(self.poz)
          //  self.Via[0] = self.vias[0]
          //else
            
        }, window.this.ApiError());

    },
    DisplayRequest() {
      window.this.$log.debug('display request')
      let self = this;
      self.actionsPozadavky.length = 0;
      var wh = new window.WorkFlow(window.channel, window.message);
      wh.GetRequest(self.poz,
        function (result2) {
          window.this.$log.debug(result2);
          self.state = result2.ReqStateText.value;
          self.stateid = result2.ReqState.value;
          for (var x = 0; x < result2.Actions.value.items.length; x++) {
                        var id = result2.Actions.value.items[x].Id.value;
                        var colorClass = id === 0 ? "buttonKeSchvaleni" : (id === 4 ? "buttonUlozitProUpravy" : (id === -1 ? "buttonSmazat" : "buttonDefault"));
                        var iconName = id === 0 ? "upload" : (id === 4 ? "floppy" : (id === -1 ? "trash" : ""));
                        self.actionsPozadavky[self.actionsPozadavky.length] = {
                            nazev: result2.Actions.value.items[x].Value.value,
                            id: result2.Actions.value.items[x].Id.value,
                            class: colorClass,
                            icon: iconName,
                            pozid: self.poz
            };
          }
        }, window.this.ApiError()); // window.this.$log.debug('chyba')); //
    },
    onFormSubmit(e) {

      window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
      window.this.$log.debug(e)
      this.SetContract()
      e.preventDefault(); 
      
    },
    TypySmlouvy() {
      let self = this;
      var gen = new window.General(window.channel, window.message);
      self.smlouvyTypy.length = 0
      gen.ListLookup('SmlTyp','', 1, 25,
        function (result) {
          window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self.smlouvyTypy.length;
            self.smlouvyTypy[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
          }

        }, window.this.ApiError());
    },
    setSelectedValueInput(event,intType){
      let self=this;
      window.this.$log.debug(event,intType);
      if (intType==1){
        if(event.returnValue==true){
          self.formData.podpis=1;
      }
        window.this.$log.debug('this.formData.podpis 1',this.formData.podpis);
      } else if (intType==2){
        if(!event.returnValue==false){
          self.formData.podpis=0;
      }
        window.this.$log.debug('this.formData.podpis 2',this.formData.podpis);
      }
      if(!self.zakladani && !this.hasValidationError){
        self.SetContract()
      }
      
    },
    setSelectedValue(e) {
      window.MountCount++
      window.this.$log.debug(e);
      window.this.$log.debug(window.MountCount + 'MountCount');
      window.this.$log.debug(this.PocetKolonek + 'Dokolika');
      if (window.MountCount == this.PocetKolonek) this.MountingData = false // tohle neni uplne dobry napad delat
      switch (e.element.id) {
        case '1':
          if (e.value == '') return 1;
          this.formData.nazev = e.value
          break;
        case '2':
          if (e.value == '') return 1;
          this.formData.predmet = e.value
          break;
        case '3':
          if (e.value == null) return 1;
          this.formData.cena = e.value
          break;
        case '4':
          this.formData.typSmlouvy = e.value
          //this.komentar = e.value
          break;
        case '6':
          //this.komentar = e.value
          break;  
        case '7':
          //this.zverejnit = e.value
          break;
          /*
        case 'employee':
          this.formData.referent = e.value
          //this.zverejnit = e.value
          break;
          */
        case 'suppliers':
          if (e.value == null) {
            this.$log.debug('asdasdasd')
            break;
          }
          this.formData.protistrana = e.value
          //this.zverejnit = e.value
          break;
          case 'ownerchanged':
          this.$router.push('Pozadavky')
          return 1;
      }
      if (!isUndefined(e.value) && !isNull(e.value) && !this.zakladani && !this.MountingData && !this.hasValidationError ) 
        this.SetContract()
      // this.SkipDiacritics(this.typpozadavek2.content)
      //  this.pozadavek=e.value
      // this.selectedValue = e.value;
    },
    SetContract() {
      var self = this;
      var wh = new window.WorkFlow(window.channel, window.message);
      var protistrana;
      window.this.$log.debug(this.formData.protistrana)
      if (!isUndefined(this.formData.protistrana.id))
        protistrana = this.formData.protistrana.id;
      else protistrana = undefined
      var protistranaTXT = ''
      window.this.$log.debug(this.formData.protistrana)
      if (protistrana == undefined || protistrana == null || protistrana == 0) {
        protistranaTXT = this.formData.protistrana.content;
        protistrana = 0;
      }
      if (self.formData.podpis!=undefined){
      //  0, 0, '', 0, '', '', 1000, 6374, 175811, 175812, true, '',
      this.$log.debug(this.formData)
      wh.SetContract(self.poz, 0, self.formData.nazev, protistrana, protistranaTXT, self.formData.predmet, self.formData.cena, 
        self.formData.typSmlouvy, self.formData.zverejnit, self.formData.podpis, self.formData.komentar,
        function (result) {

          window.this.$log.debug(result);
              
          if (result.Error.value.ErrorNum.value == 0) {
            //console.log(self.poz)
            if (self.poz == 0) {
              self.poz = result.Value.value
              window.localStorage.setItem("pozid", result.Value.value)
              self.zakladani = false
              self.MountingData = false
              notify({
                message: self.$t('Smlouvy-SmlouvaZaložena'),
                position: {
                  my: 'center top',
                  at: 'center top',
                },
              }, 'success', 3000);  
              self.DisplayRequest()
            }
            else { 
                notify({
                message: self.$t('Smlouvy-SmlouvyZmenyUlozeny'),
                position: {
                  my: 'center top',
                  at: 'center top',
                },
                }, 'success', 3000);
            }
                
              }
              else {
                notify({
                  message: result.Error.value.ErrorTextCZ.value,
                  position: {
                    my: 'center top',
                    at: 'center top',
                  },
                }, 'error', 3000);

              }
            }, window.this.ApiError());
          } else {
            notify({
                message: self.$t('Smlouvy.MusiteZvolitTypPodpisu'),
                position: {
                  my: 'center top',
                  at: 'center top',
                },
              }, 'error', 3000);
          }      
    },
  }
};
</script>



<style scoped> 
.ResponsiveBoxClass{
      padding: 10px;
}
#DruhyResponsiveBox{
  border-style: ridge;
  border-color: rgba(128, 128, 128, 0.1);
  border-radius: 10px;
  
  background-color: rgba(128, 128, 128, 0.075);
  flex: none !important;
}
#obaleniFormulare2{
  padding:10px;
}
.SmlouvaBox{
  padding: 5px;
}
#obalenigrid{
  padding:10px;
}

#SmlouvaResponsiveBox{
  border-style: ridge;
    border-color: rgba(128, 128, 128, 0.1);
    border-radius: 10px;
    background-color: rgba(128, 128, 128, 0.075);
    padding: 5px;
}
#checkboxzverejnit{
  padding-top: 25px;
  
}
.checkboxzverejnitZvyraz{
  padding: 5px;
  border-style: ridge;
  border-color: rgba(46, 46, 46, 0.1);
  border-radius: 10px;
  background-color: rgba(170, 3, 3, 0.459);
   width: max-content;
   font-weight: bold;
}
.buttonDefault:hover,.buttonUlozitProUpravy:hover,.buttonKeSchvaleni:hover,.buttonSmazat:hover,.buttonKEditaci:hover{
    background-color: var(--colorByThemeLighter);
    border: 0px;
    
}
.buttonKeSchvaleni{
  background: #5cb83c;
  border-color: none;
  border: 0px;
}
.buttonUlozitProUpravy{
    background: #3cb8b8;
    border-color: none; 
    border: 0px;
}
.buttonSmazat{
    background: #d9534f;
  border-color: none;
  border: 0px;
}
.buttonKEditaci{
    background: #ffcc33;
    border-color: none;
    border: 0px; 
}
.buttonDefault{
    background: none;
    border-color: none;
    border: 0px;
}
.clCheckBoxForma{
  margin-top:0px !important;  
}
.radio-option {
  display: inline-block;
  margin-right: 20px; 
  margin-bottom: 5px;
  margin-top: 5px;
}
.radio-option input[type="radio"] {
  margin-right: 5px; 
}
.radio-option label {
  font-weight: normal; 
}
.flexColumn{
  display: flex;
  flex-direction: column;
}
#smlouvyZpusobPodpis{
  margin: 2px 0px;
  font-weight: bold;
}
</style>